const COMMANDS = {
  ADD_DAYS: {
    key: 'ADD_DAYS',
    label: 'Add Days',
  },
  SET_VALUE: {
    key: 'SET_VALUE',
    label: 'Set Value',
  },
};

export default COMMANDS;
